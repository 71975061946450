var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "dn-video-tile elevation-5",
      on: {
        click: function ($event) {
          return _vm.$router.push("/video/" + _vm.video._id)
        },
      },
    },
    [
      _c("v-img", {
        staticClass: "dn-video-tile__img",
        attrs: { src: _vm.thumbnail, "aspect-ratio": 16 / 9 },
      }),
      _c("v-card-text", [
        _c("p", { staticClass: "dn-video-tile__type" }, [_vm._v("Video")]),
        _c("p", { staticClass: "dn-video-tile__title" }, [
          _vm._v(_vm._s(_vm.video.title)),
        ]),
        _c("p", { staticClass: "dn-video-tile__subtitle" }, [
          _vm._v(_vm._s(_vm.video.subtitle)),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }