import { render, staticRenderFns } from "./PackTile.vue?vue&type=template&id=767a36c2&scoped=true&"
import script from "./PackTile.vue?vue&type=script&lang=ts&"
export * from "./PackTile.vue?vue&type=script&lang=ts&"
import style0 from "./PackTile.vue?vue&type=style&index=0&id=767a36c2&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "767a36c2",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VImg})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('767a36c2')) {
      api.createRecord('767a36c2', component.options)
    } else {
      api.reload('767a36c2', component.options)
    }
    module.hot.accept("./PackTile.vue?vue&type=template&id=767a36c2&scoped=true&", function () {
      api.rerender('767a36c2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ui/PackTile.vue"
export default component.exports