var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "dn-hero-tile",
      attrs: { mouseleave: _vm.toggleHover },
      on: { mouseenter: _vm.toggleHover },
    },
    [
      _c("v-img", {
        staticClass: "dn-hero-tile__img",
        attrs: { src: _vm.src, "aspect-ratio": 16 / 9 },
      }),
      _c("h2", { staticClass: "dn-hero-tile__title" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }