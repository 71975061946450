var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", _vm._b({}, "div", _vm.carouselAttrs, false), [
    _c("div", { staticClass: "dn-carousel__header" }, [
      _c("div", [
        _c("p", { staticClass: "dn-carousel__title" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
        _c("p", { staticClass: "dn-carousel__subtitle" }, [
          _vm._v(_vm._s(_vm.subtitle)),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$vuetify.breakpoint.smAndUp,
              expression: "$vuetify.breakpoint.smAndUp",
            },
          ],
          staticClass: "dn-carousel__controls",
          attrs: { "data-glide-el": "controls" },
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "dn-carousel__btn",
              attrs: { icon: "", outlined: "", "data-glide-dir": "<" },
            },
            [_c("v-icon", [_vm._v(" keyboard_arrow_left ")])],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "dn-carousel__btn",
              attrs: { icon: "", outlined: "", "data-glide-dir": ">" },
            },
            [_c("v-icon", [_vm._v(" keyboard_arrow_right ")])],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "dn-carousel__container glide" }, [
      _c(
        "div",
        {
          staticClass: "dn-carousel__track glide__track",
          attrs: { "data-glide-el": "track" },
        },
        [
          _c(
            "ul",
            { staticClass: "dn-carousel__slides glide__slides" },
            _vm._l(_vm.items, function (item, i) {
              return _c(
                "li",
                { key: i, staticClass: "glide__slide" },
                [
                  _vm.type === "hero"
                    ? [
                        _c("hero-tile", {
                          attrs: { src: item.coverUrl, title: item.title },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.$router.push({
                                path: "article/" + item.slug,
                                params: { article: item },
                              })
                            },
                          },
                        }),
                      ]
                    : _vm.type === "video"
                    ? [_c("video-tile", { attrs: { video: item } })]
                    : [_c("pack-tile", { attrs: { pack: item } })],
                ],
                2
              )
            }),
            0
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }