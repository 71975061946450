var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dn-pack-tile transparent elevation-0",
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.$router.push("/pack/" + _vm.pack._id)
        },
      },
    },
    [
      _c("v-img", {
        staticClass: "dn-pack-tile__img",
        attrs: { src: _vm.pack.thumbnailUrl, "aspect-ratio": 1 },
      }),
      _c("p", { staticClass: "dn-pack-tile__name" }, [
        _vm._v(_vm._s(_vm.pack.name)),
      ]),
      _c("p", { staticClass: "dn-pack-tile__type" }, [
        _vm._v(" " + _vm._s(_vm.getPackYear(_vm.pack.addedOn)) + " "),
        _c("strong", { staticStyle: { "margin-left": "1rem" } }, [
          _vm._v(" Vol. " + _vm._s(_vm.pack.volume) + " "),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }